import { invokeApi } from "../../bl_libs/invokeApi";

export const MemberListing = async (data) => {
  const requestObj = {
    path: `api/member/active_member_list_by_coach`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addPersonalNote = async (data) => {
  const requestObj = {
    path: `api/member/update_personal_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getNinetyDayQuestionApi = async (data) => {
  const requestObj = {
    path: `api/ninteen_day_vision_questions/get_question`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberGroupListApi = async (id) => {
  const requestObj = {
    path: `api/member/member_group_list/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberDetailApi = async (id) => {
  const requestObj = {
    path: `api/member/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AllMemberListing = async (data) => {
  const requestObj = {
    path: `api/member/member_list_by_coach`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddMemberApi = async (data) => {
  const requestObj = {
    path: `api/member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const memberListing = async (page, limit, search, data, goalStatus) => {
  const requestObj = {
    path: `api/member/member_list?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: search,
  };
  return invokeApi(requestObj);
};
export const member_login_api = async (data) => {
  const requestObj = {
    path: `/app/token_generate_from_impersonate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteMemberApi = async (id) => {
  const requestObj = {
    path: `api/member/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteMultiMemberApi = async (data) => {
  console.log(...data, "new arraysapi side");
  const requestObj = {
    path: `api/member/delete_all_and_specific_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditMemberApi = async (data, id) => {
  const requestObj = {
    path: `api/member/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
