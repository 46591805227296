import { invokeApi } from "../../bl_libs/invokeApi";

export const WorkoutTypeListingApi = async (page, limt, search) => {
  const requestObj = {
    path: `api/workout_type?page=${page}&limit=${limt}&search=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AllActiveWorkoutTypeListingApi = async () => {
  const requestObj = {
    path: `api/workout_type/all_active/workout_types`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const exercise_detail_api = async (slug) => {
  const requestObj = {
    path: `api/exercise/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const AddWorkoutTypeApi = async (data) => {
  const requestObj = {
    path: `api/workout_type`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const EditWorkoutTypeApi = async (data, slug) => {
  const requestObj = {
    path: `api/workout_type/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteWorkoutTypeApi = async (slug) => {
  const requestObj = {
    path: `api/workout_type/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
