import React, { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  Container,
  Button,
  FormControl,
  Typography,
  FormGroup,
  Grid,
  Box,
  Stack,
  IconButton,
  Card,
  Checkbox,
  Radio,
  Backdrop,
  CircularProgress,
  Divider,
  RadioGroup,
  FormControlLabel,
  Chip,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { getSurveyTemplateDetail } from "src/DAL/surveyTemplates";
import { logo } from "src/assets";
// import { getFeedbackDetail, submitForm } from "src/DAL/form_template";
import moment from "moment";
import RatingComponent from "./component/RaingStylesForFeedback";
import { submitForm } from "src/DAL/Forms/forms";
import AvatarGalleryPreview from "./component/imageViewOnLengthPreview";
export default function DetailsCoachFormInformation() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // const location = useLocation();
  const { state } = useLocation();
  const param = useParams();
  const _id = param.slug;
  const [templateTitle, setTemplateTitle] = useState("");
  // const anchorRef = useRef(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [privateNote, setPrivateNote] = useState("");
  const handleBack = () => {
    navigate(-1);
  };

  const handleChangeText = (e, index) => {
    questions[index].answer = e.target.value;
    setQuestions([...questions]);
  };
  const handleChangeDescription = (e, index) => {
    questions[index].description = e.target.value;
    setQuestions([...questions]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let noAnswer = false;
    let question = [];
    questions.map((item, index) => {
      if (item?.type == "rating" && item?.answer == "") {
        question.push(index + 1);
        // enqueueSnackbar(`Please Answer the Question:${index + 1}`, {
        //   variant: "error",
        // });
        noAnswer = true;
      }
    });
    if (!noAnswer) {
      let postData = {
        questions: questions,
      };
      console.log(postData, _id, "postData");

      try {
        const res = await submitForm(_id, postData);
        if (res.code === 200) {
          navigate(-1);
          enqueueSnackbar(res.message, { variant: "success" });
        } else {
          enqueueSnackbar(res.message, { variant: "error" });
        }
      } catch (error) {
        console.log(error);
      } finally {
        // setLoading(false);
      }
    } else {
      enqueueSnackbar(`Please Answer the Question:${question[0]}`, {
        variant: "error",
      });
      return;
    }
  };

  useEffect(() => {
    console.log(questions, "djgfjsdahaf");
  }, [questions]);
  useEffect(() => {
    setTemplateTitle(state);
    setQuestions(state?.questions);
    console.log(state, "sdafjkajsdahf");
  }, [state]);
  //===================================================================

  return (
    <Container sx={{ width: { sm: 950, md: 950 } }}>
      <Backdrop
        invisible="true"
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between"
      >
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
      </Stack>{" "}
      <>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Stack
            direction="column"
            alignItems="center"
            spacing={1.5}
            justifyContent="space-between"
            mb={4.5}
          >
            {/* <img loading="lazy" src={logo} height={100} width={100} /> */}
            {/* <img
              loading="lazy"
              src={logo}
              height="100px"
              width="100px"
              alt="logo"
            /> */}
            <h2 className="text-start w-100">{templateTitle?.title}</h2>
            <Typography
              className="text-start w-100"
              sx={{ fontWeight: "500", fontSize: 16, opacity: 0.6 }}
            >
              {templateTitle?.description}
            </Typography>
            {/* <Grid
              container
              sx={{
                width: { sm: "100%", md: "100%" },
              }}
              rowSpacing={1}
              columnSpacing={{ xs: 0, sm: 0, md: 2 }}
            >
              <Grid item md={6} xs={12}>
                <div className="d-flex">
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: 14,
                      mr: 1,
                      mb: 0,
                      width: 140,
                    }}
                  >
                    Submitted By:
                  </Typography>
                  <div className="w-100">
                    <Typography sx={{ fontSize: 14 }}>
                      {templateTitle?.send_to?.name
                        ? templateTitle?.send_to?.name
                        : templateTitle?.send_for?.name}
                    </Typography>
                    <Divider
                      sx={{ width: "100%", borderColor: "black" }}
                    ></Divider>
                  </div>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="d-flex">
                  <Typography
                    sx={{ fontWeight: "500", fontSize: 14, mr: 1, mb: 0 }}
                  >
                    For:
                  </Typography>
                  <div className="w-100">
                    <Typography sx={{ fontSize: 14 }}>
                      {templateTitle?.send_for?.name}
                    </Typography>
                    <Divider
                      sx={{ width: "100%", borderColor: "black" }}
                    ></Divider>
                  </div>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="d-flex">
                  <Typography
                    sx={{ fontWeight: "500", fontSize: 14, mr: 1, mb: 0 }}
                  >
                    Date:
                  </Typography>
                  <div className="w-100">
                    <Typography sx={{ fontSize: 14 }}>

                      {templateTitle?.month
                        ? moment(templateTitle?.month, "MM").format("MMM") +
                          " " +
                          templateTitle?.year
                        : "_ _ / _ _ "}
                    </Typography>
                    <Divider
                      sx={{ width: "100%", borderColor: "black" }}
                    ></Divider>
                  </div>
                </div>
              </Grid>
            </Grid> */}

            {questions &&
              questions.map((item, index) => {
                // console.log(item, "afsoiusd");
                return (
                  <Card
                    // variant="outlined"
                    // square={true}
                    sx={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      mb: 1,
                      width: { sm: "100%", md: "100%" },
                      margin: "auto",
                      p: 2,
                      backgroundColor: "",
                      borderRadius: 0.5,

                      // background:
                      //   "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
                      position: "relative",
                    }}
                  >
                    {item?.required && (
                      <Chip
                        label={"Required"}
                        variant="contained"
                        color={"error"}
                        sx={{
                          fontSize: "10px",
                          height: "auto",
                          width: "auto",
                          position: "absolute",
                          top: 8,
                          left: 16,
                        }}
                      />
                    )}
                    <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                        mt={1}
                      >
                        <Grid item md={12} xs={12}>
                          <div className="d-flex align-items-center mb-2">
                            <div className="">
                              <Typography
                                sx={{ fontWeight: "900", fontSize: 16, mr: 1 }}
                              >
                                Q{index + 1}.
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: 14, mr: 1 }}
                              >
                                {item?.title}
                              </Typography>
                            </div>
                          </div>
                        </Grid>

                        {item?.type == "multiple_choice" ? (
                          <Grid item md={12} xs={12}>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={item?.answer}
                                onChange={(e) => {
                                  questions[index].answer = e.target.value;
                                  setQuestions([...questions]);
                                }}
                              >
                                {item?.options.map((item) => {
                                  return (
                                    <Grid item md={12}>
                                      <FormControlLabel
                                        value={item}
                                        disabled
                                        control={
                                          <Radio
                                            sx={{
                                              "&.Mui-disabled": {
                                                color: "#212B36", // Color for disabled checkbox
                                              },
                                              "&.Mui-checked": {
                                                color: "#0096FF", // Color for checked checkbox
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <span style={{ color: "#212B36" }}>
                                            {item}
                                          </span>
                                        }
                                      />
                                    </Grid>
                                  );
                                })}{" "}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        ) : item?.type == "multiple_select" ? (
                          <Grid item md={12} xs={12}>
                            <FormControl>
                              {/* <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name={`controlled-radio-buttons-group-${index}`}
                                value={questions[index]?.answer}
                                onChange={(e) => {
                                  const selectedOptions =
                                    questions[index]?.answer || [];

                                  if (
                                    selectedOptions.includes(e.target.value)
                                  ) {
                                    // If the option is already selected, remove it
                                    const updatedOptions =
                                      selectedOptions.filter(
                                        (option) => option !== e.target.value
                                      );
                                    questions[index].answer = updatedOptions;
                                  } else {
                                    // If the option is not selected, add it
                                    questions[index].answer = [
                                      ...selectedOptions,
                                      e.target.value,
                                    ];
                                  }

                                  setQuestions([...questions]);
                                }}
                              >
                                {item?.options.map((option, optionIndex) => (
                                  <Grid item md={12} key={optionIndex}>
                                    <FormControlLabel
                                      value={option}
                                      control={<Radio />}
                                      label={option}
                                    />
                                  </Grid>
                                ))}
                              </RadioGroup> */}
                              <FormGroup>
                                {item?.options.map((option) => (
                                  <Grid item md={12} key={option}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          sx={{
                                            "&.Mui-disabled": {
                                              color: "#212B36", // Color for disabled checkbox
                                            },
                                            "&.Mui-checked": {
                                              color: "#0096FF", // Color for checked checkbox
                                            },
                                          }}
                                          disabled
                                          checked={questions[
                                            index
                                          ]?.multiple_answers.includes(option)}
                                          //   onChange={(e) => {
                                          //     const checked = e.target.checked;

                                          //     const updatedAnswers = checked
                                          //       ? [
                                          //           ...questions[index]
                                          //             ?.multiple_answers,
                                          //           option,
                                          //         ]
                                          //       : questions[
                                          //           index
                                          //         ]?.multiple_answers.filter(
                                          //           (value) => value !== option
                                          //         );

                                          //     questions[index].multiple_answers =
                                          //       updatedAnswers;
                                          //     setQuestions([...questions]);
                                          //   }}
                                        />
                                      }
                                      label={
                                        <span style={{ color: "#212B36" }}>
                                          {option}
                                        </span>
                                      }
                                    />
                                  </Grid>
                                ))}
                              </FormGroup>
                            </FormControl>
                            {/* */}
                          </Grid>
                        ) : item?.type == "image" ? (
                          <Grid item md={12} xs={12}>
                            <div className="d-flex align-items-center mb-2">
                              {/* {console.log(item.answer, "dsfsdfsdfasdfadsfa")} */}
                              <div className="">
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    mr: 1,
                                  }}
                                >
                                  {item?.options[0]}
                                </Typography>
                              </div>
                              {/* <div>
                                <Rating
                                  name="read-only"
                                  value={item?.answer}
                                  max={item?.options_count}
                                  onChange={(event, newValue) => {
                                    questions[index].answer = newValue
                                      ? `${newValue}`
                                      : "0";
                                    setQuestions([...questions]);
                                  }}
                                />
                              </div> */}
                              {/* <RatingComponent
                                rating={item?.answer}
                                details={false}
                                maxLength={item?.options_count}
                                index={index}
                                questions={questions}
                                setQuestions={setQuestions}
                              /> */}
                              <AvatarGalleryPreview
                                length={item?.options_count}
                                Index={index}
                                questions={questions}
                                Image={item?.multiple_answers}
                                setQuestions={setQuestions}
                              />
                            </div>
                          </Grid>
                        ) : (
                          item?.type == "free_text" && (
                            <Grid item md={12} xs={12}>
                              <Typography
                                sx={{
                                  fontSize: 14,

                                  mb: 0,
                                  width: "100%",
                                }}
                              >
                                {item?.answer && (
                                  <span>
                                    <b>
                                      <i>Answer: </i>
                                    </b>
                                  </span>
                                )}
                                {item?.answer ? item?.answer : "No Answer"}
                              </Typography>
                              {/* <TextField
                                id="outlined-multiline-static"
                                label="Description"
                                multiline
                                  disabled
                                rows={2}
                                value={item?.description}
                                sx={{ width: "100%", mt: 2 }}
                                onChange={(e) =>
                                  handleChangeDescription(e, index)
                                }
                              /> */}
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>
                  </Card>
                );
              })}
          </Stack>
        </form>
      </>
    </Container>
  );
}
