import {
  Autocomplete,
  Avatar,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SyncIcon from "@mui/icons-material/Sync";

import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CloseIcon from "@mui/icons-material/Close";
import { ExerciseListingApi } from "src/DAL/exercise/exercise";

import { s3baseUrl } from "src/config/config";
import { AddWorkoutApi, EditWorkoutApi } from "src/DAL/workout/workout";
import { get_root_value } from "src/utils/domUtils";
import { PhotoCamera } from "@mui/icons-material";
import SuperSet from "./components/SuperSet";
import faker from "faker";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import ToshowExercise from "./components/modalToshowExercise";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 20,
    },
  },
};
const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));
const finalSpaceCharacters = [
  {
    id: "gary",
    name: "Gary Goodspeed",
    thumb: "/images/gary.png",
  },
  {
    id: "cato",
    name: "Little Cato",
    thumb: "/images/cato.png",
  },
  {
    id: "kvn",
    name: "KVN",
    thumb: "/images/kvn.png",
  },
  {
    id: "mooncake",
    name: "Mooncake",
    thumb: "/images/mooncake.png",
  },
  {
    id: "quinn",
    name: "Quinn Ergon",
    thumb: "/images/quinn.png",
  },
];

const EditWorkout = ({}) => {
  // console.log(addArray, setaddArray, "dflkadsjjkfahds");
  const [characters, updateCharacters] = useState(finalSpaceCharacters);
  const { state } = useLocation();
  const myInputRef = useRef(null);
  const [serchText, setSerchText] = useState("");
  const [addArray, setaddArray] = useState([
    {
      id: "coin",
      exercise: "",
      set: "",
      time: "",
      weight: "",
      weightType: "kg",
      reps: "",
      rest: "",
      description: "",
      superset_sets: "",
    },
  ]);

  const [navItems, setNavitems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isReaload, setIsReaload] = useState(false);
  const [exercise, setExercise] = useState([]);
  const [workoutTitle, setWorkoutTitle] = useState(state.title);
  const [file, setImage] = React.useState({});
  const [previews, setPreviews] = useState("");
  const [imageStatus, setImageStatus] = useState(false);
  const [oldProfile, setOldProfile] = useState();
  const [detailsData, setDetailsData] = useState("");
  const [openDetails, setOpenDetails] = useState(false);
  const [workoutDescription, setWorkoutDescription] = useState(
    state.description
  );
  const handleUpload = (event) => {
    // console.log("dsfksadjflkas", event.target.files[0]);
    setImageStatus(true);
    // setImage(event.target.files[0]);
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [menuLists, setMenuList] = useState([
    "5 sec",
    "10 sec",
    "15 sec",
    "20 sec",
    "25 sec",
    "30 sec",
    "35 sec",
    "40 sec",
    "45 sec",
    "50 sec",
    "55 sec",
    "60 sec",
    "1 min",
    "2 min",
    "3 min",
    "4 min",
    "5 min",
    " 10 min",
    " 15 min",
    " 20 min",
    " 25 min",
    " 30 min",
  ]);
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus1 = () => {
    setIsFocused1(true);
  };

  const handleBlur1 = () => {
    setIsFocused1(false);
  };
  function durationToSeconds(durationString) {
    // Regular expression to match a number and a time unit (sec, min, hour, etc.)
    const regex = /(\d+)\s+(sec|min|hour|hr|day|week|month|year)s?/i;

    // Use the regex to extract the number and unit from the input string

    const match = durationString && durationString.match(regex);

    if (match) {
      const value = parseInt(match[1], 10);
      const unit = match[2].toLowerCase();

      // Define conversion factors for different time units
      const unitToSeconds = {
        sec: 1,
        min: 60,
        hour: 3600,
        hr: 3600,
        day: 86400,
        week: 604800,
        month: 2592000,
        year: 31536000,
      };

      if (unitToSeconds.hasOwnProperty(unit)) {
        return value * unitToSeconds[unit];
      }
    }

    return 0; // Invalid duration format
  }
  const handleAdd = (i) => {
    let list = addArray;
    list.splice(i + 1, 0, {
      exercise: "",
      set: "",
      time: "",
      weight: "",
      weightType: "kg",
      reps: "",
      rest: "",
      description: "",
      _id: faker.datatype.uuid(),
      superSet: [],
    });
    setaddArray([...list]);
  };
  const addSuperSet = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[index] };
    current.superSet = [];
    let array = [current];

    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }

    const list = [...addArray];
    list[index]["superSet"] = array;
    setaddArray(list);
    handleDelete(index + 1);
  };
  const addInSuperSet = (index) => {
    //  const { name, value } = e.target;

    let array = addArray[index].superSet;
    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }

    const list = [...addArray];
    list[index]["superSet"] = array;
    setaddArray(list);
    handleDelete(index + 1);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  // console.log(level, "levellevellevel");
  const get_accessment_setting = async () => {
    setIsReaload(true);
    const result = await _get_attitude_accessment_setting();
    if (result.code == 200) {
      // console.log(result.assessment_setting[level], "level of Dynamite");
      if (result.assessment_setting[level]?.length > 0) {
        const config_array = result.assessment_setting[level]?.map(
          (config, i) => {
            return {
              ...config,
              id: "coin" + i,
            };
          }
        );
        setaddArray(config_array);
      }
      setIsReaload(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsReaload(false);
    }
  };

  const handleArrow = (value, index) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[index]["open"] = value;
    setaddArray(list);
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;
    setaddArray(list);
  };
  const handleChangeOther = (e, index) => {
    console.log(e, "sdkfjksdfa");
    const list = [...addArray];

    list[index]["exercise"] = e;
    list[index]["description"] = e?.instructions;
    setaddArray(list);
  };
  function handleOnDragEnd(result) {
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setaddArray(items);
  }
  const handleChangeNavItem = (event, index) => {
    let list = [...addArray];
    const {
      target: { value },
    } = event;
    list[index]["nav_items"] =
      typeof value === "string" ? value.split(",") : value;

    setaddArray(list);
    setNavitems(typeof value === "string" ? value.split(",") : value);
  };
  const getExerciseList = async () => {
    let result = await ExerciseListingApi(0, 100, serchText);
    if (result.code == 200) {
      setIsLoading(false);
      console.log(result, "lessonListingApi");
      setExercise(result?.exercises);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let exerciesArray = addArray.map((item) => {
      console.log(item?.superSet[0]?.exercise?._id, "dsflsdkjfa");
      let data = {
        exercise_id: item?.superSet[0]?.exercise?._id
          ? item?.superSet[0]?.exercise?._id
          : item.exercise?._id,
        title: item?.superSet[0]?.exercise?.title
          ? item?.superSet[0]?.exercise?.title
          : item.exercise?.title,
        description: item.description,
        unit: item.weightType,
      };
      if (item.superset_sets) {
        data["superset_sets"] = item.superset_sets;
      }
      if (item.set) {
        data["sets"] = item.set;
      }
      if (item.reps) {
        data["reps"] = item.reps;
      }
      if (item.rest) {
        data["rest"] = item.rest;
      }
      if (item.weight) {
        data["weight"] = item.weight;
      }
      if (item.time) {
        data["duration"] = item.time;
      }
      if (item.superSet) {
        data["sub_exercises"] = [];
        if (item.superSet.length > 0) {
          data["sub_exercises"] = item.superSet.map((item) => {
            console.log(item, "kfkjdsklfjlas");
            let data = {
              exercise_id: item.exercise?._id,
              title: item.exercise?.title,
              description: item.description,
              unit: item.weightType,
            };

            if (item.set) {
              data["sets"] = item.set;
            }
            if (item.reps) {
              data["reps"] = item.reps;
            }
            if (item.rest) {
              data["rest"] = item.rest;
            }
            if (item.weight) {
              data["weight"] = item.weight;
            }
            if (item.time) {
              data["duration"] = item.time;
            }

            return data;
          });
        }
      }
      return data;
      return {
        // sets: item.set,
        // reps: item.reps,
        // rest: item.rest,
        // weight: item.weight,
        // duration: item.time,
      };
    });
    const formData = new FormData();
    formData.append("title", workoutTitle);

    // formData.append("image", state.image);
    if (imageStatus) {
      formData.append("image", file);
    }
    formData.append("type", state.type);
    formData.append("description", workoutDescription);
    formData.append("status", state.status);
    formData.append("exercises", JSON.stringify(exerciesArray));
    console.log(exerciesArray, "dskjfhakjdsh");
    console.log(...formData, "dskjfhakjdsh");

    const result = await EditWorkoutApi(formData, state.workout_slug);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);
    setaddArray(items);
  };
  const handleExerciseList = (value) => {
    setDetailsData(value);
    setOpenDetails(true);
  };
  const handleSelectExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;
    setaddArray(list);
  };
  const handleChangeDown = (value) => {
    console.log(value, "fkdshjkfahdkjha", addArray.length);
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);
    setaddArray(items);
  };
  const memueOptions = (value) => {
    console.log(value, "thethe");
    const MENU_OPTIONS = [];

    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
    );

    return MENU_OPTIONS;
  };
  const MENU_OPTIONS_MOVE = [
    {
      label: "Move Up",
      icon: "mdi:arrow-up",
      // handleClick: handleSectionChangeUpConfirmation,
    },
    {
      label: "Move Down",
      icon: "ic:outline-arrow-downward",
      // handleClick: handleSectionChangeDownConfirmation,
    },
  ];

  useEffect(() => {
    getExerciseList();
  }, [serchText]);
  useEffect(() => {
    setOldProfile(state?.image?.thumbnail_1);
    setExercise(state.exercises);
    let givenExercise = state.exercises.map((item) => {
      let SubSet = item?.sub_exercises?.map((item) => {
        return {
          _id: item?._id,
          exercise: item?.exercise_id,
          set: item?.sets,
          time: item?.duration,
          weight: item?.weight,
          weightType: item?.unit,
          reps: item?.reps,
          rest: item?.rest,
          description: item?.description,
          superSet: [],
        };
      });

      return {
        _id: item?._id,
        exercise: item?.exercise_id,
        set: item?.sets,
        time: item?.duration,
        weight: item?.weight,
        weightType: item?.unit,
        reps: item?.reps,
        rest: item?.rest,
        description: item?.description,
        superSet: SubSet,
        superset_sets: item?.superset_sets,
      };
    });
    console.log(givenExercise, "sdkfhjkdshkafj");
    setaddArray(givenExercise);
  }, [state]);
  console.log(exercise, "dfkjdshfja");
  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(state, "sdjkfklsjdlfka");
  console.log(addArray, "addArray ok");
  return (
    <div className="container" style={{ minWidth: "936px" }}>
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <div className=" mt-3">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/addreview/`)}
          >
            Add Programme Review
          </button> */}
        </div>
        <div className="row">
          {" "}
          <div className="col-10">
            {/* <h1 className="programmes-heading">{state?.title}</h1> */}
            <FormControl
              className={!isFocused ? classes.customInput : ""}
              sx={{ width: "70%" }}
            >
              <TextField
                id="outlined-basic"
                // label="Exercise"
                variant={"outlined"}
                onFocus={handleFocus}
                className="title-Test"
                onBlur={handleBlur}
                placeholder="Workout Title"
                value={workoutTitle}
                name="workoutTitle"
                onChange={(e) => {
                  if (e.target.value.length <= 30)
                    setWorkoutTitle(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "18px" },
                }}
                InputProps={{
                  style: {
                    fontSize: "32px",
                    fontWeight: "500",
                  },
                }}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },

                  fontSize: "13px",
                  // width: "10%",
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={!isFocused1 ? classes.customInput : ""}
            >
              <TextField
                id="outlined-basic"
                className="desc-Test"
                // label="Exercise"
                variant={"outlined"}
                onFocus={handleFocus1}
                onBlur={handleBlur1}
                placeholder="Workout description"
                value={workoutDescription}
                multiline
                rows={3}
                name="workoutTitle"
                onChange={(e) => {
                  if (e.target.value.length <= 250)
                    setWorkoutDescription(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "18px" },
                }}
                InputProps={{
                  style: {
                    fontSize: "14px",
                    fontWeight: "500",
                    padding: 0,
                  },
                  maxLength: 10,
                }}
                sx={{
                  borderRadius: "5px",

                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  mb: 1,
                  // width: "10%",
                }}
              />
            </FormControl>
            {/* <div className="col-12 section-space">
            <p>
              <div
                dangerouslySetInnerHTML={{
                  // __html: lessonDetailInfo?.detailed_description,
                  __html: state?.description,
                }}
              ></div>
            </p>
          </div> */}
          </div>
          <div className="col-2 d-flex justify-content-end">
            <div className="edit-workout-icon ">
              <Avatar
                alt={"I"}
                variant="square"
                src={previews ? previews : s3baseUrl + oldProfile}
                sx={{ width: 120, height: 120 }}
              >
                <div className="fs-1">{workoutTitle.charAt(0)}</div>
              </Avatar>
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  name="profile_image"
                  onChange={(e) => handleUpload(e)}
                />
                <IconButton
                  className="edit-profile-icon-position"
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                ></IconButton>
              </label>

              <span
                // className="small-contained-button"
                style={{
                  position: "absolute",
                  bottom: "36%",
                  right: "26%",
                  // backgroundColor: "gray",
                  // borderRadius: "50%",
                  // padding: "4px 5px",
                }}
              >
                <PhotoCamera
                  sx={{ width: 55, height: 55 }}
                  className="pointer camera-Workout"
                  // ref={anchorRef}
                  onClick={() => myInputRef.current.click()}
                />
                <input
                  hidden
                  type="file"
                  ref={myInputRef}
                  onChange={handleUpload}
                />
              </span>
            </div>
          </div>
        </div>

        <DragDropContext
          onDragEnd={handleOnDragEnd}
          className="h-100"
          isDragDisabled={true}
        >
          <Droppable droppableId="characters" isDragDisabled={true}>
            {(provided) => (
              <div
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <form onSubmit={handleSubmit}>
                  {addArray?.map((data, index) => {
                    console.log(data, "asdlkjfka");
                    return (
                      <Draggable
                        key={data._id}
                        draggableId={data._id}
                        index={index}
                        isDragDisabled={true}
                      >
                        {(provided) => (
                          <>
                            {data?.superSet && data?.superSet.length > 0 ? (
                              <>
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={{ position: "relative" }}
                                >
                                  <SuperSet
                                    Index={index}
                                    AddArray={addArray}
                                    setAddArray={setaddArray}
                                    handleSearch={handleSearch}
                                    current={data}
                                    exercise={exercise}
                                  />
                                  <span
                                    className="cross-icon"
                                    style={{
                                      position: "absolute",
                                      bottom: "-17px",
                                      width: "100%",
                                      justifyContent: "center",
                                      zIndex: 1,
                                      // backgroundColor: "gray",
                                      // borderRadius: "50%",
                                      // padding: "4px 5px",
                                    }}
                                  >
                                    {addArray.length > 1 &&
                                    index < addArray.length - 1 ? (
                                      <div className="">
                                        <SyncIcon
                                          onClick={() => addInSuperSet(index)}
                                          className="sync-icon-add"
                                        />
                                        {/* ) : (
                                      <SyncDisabledIcon
                                        onClick={() => removeSuperSet(index)}
                                        className="sync-icon-remove"
                                      />
                                    )} */}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <div
                                // direction="row"

                                className="d-flex mb-2 workout-card"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    position: "relative",
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <TextField
                                      id="outlined-basic"
                                      // label="Exercise"
                                      variant="outlined"
                                      placeholder="Add Exercise"
                                      value={data?.exercise?.title}
                                      name="Exercise"
                                      onClick={() => handleExerciseList(index)}
                                      // onChange={(e) => handleChange(e, index)}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      label="Exercise"
                                      InputProps={{
                                        style: {
                                          fontSize: "13px",
                                          paddingLeft: "6px",
                                        },
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            {data?.exercise?.image
                                              ?.thumbnail_1 && (
                                              <Avatar
                                                sx={{ borderRadius: "5px" }}
                                                alt="User Image"
                                                src={
                                                  s3baseUrl +
                                                  data?.exercise?.image
                                                    ?.thumbnail_1
                                                }
                                              />
                                            )}
                                          </InputAdornment>
                                        ),
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <div style={{ fontSize: "16px" }}>
                                              {data?.exercise ? (
                                                <EditIcon
                                                  fontSize="12px"
                                                  sx={{ opacity: 0.5 }}
                                                />
                                              ) : (
                                                <AddIcon
                                                  sx={{ opacity: 0.5 }}
                                                />
                                              )}
                                            </div>
                                          </InputAdornment>
                                        ),
                                        inputProps: {
                                          readOnly: true, // Disable editing
                                        },
                                      }}
                                      sx={{
                                        borderRadius: "5px",

                                        fontSize: "13px",
                                        width: "50%",
                                      }}
                                    />
                                    {/* <Autocomplete
                                      id="tags-outlined"
                                      options={exercise}
                                      getOptionLabel={(option) => option.title}
                                      renderOption={(props, option) => (
                                        <li {...props}>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src={
                                                s3baseUrl +
                                                option?.image?.thumbnail_1
                                              }
                                              alt={option.title}
                                              style={{
                                                width: 30,
                                                height: 30,
                                                marginRight: 10,
                                                borderRadius: "50%",
                                              }}
                                            />
                                            {option.title}
                                          </div>
                                        </li>
                                      )}
                                      filterSelectedOptions
                                      value={
                                        data.exercise ? data.exercise : null
                                      }
                                      onChange={(event, newValue) => {
                                        handleChangeOther(newValue, index);
                                      }}
                                      sx={{ width: "50%", fontSize: "13px" }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          sx={{
                                            //   borderRadius: "5px",
                                            //   "& legend": { display: "none" },
                                            //   "& fieldset": { top: 0 },

                                            //   // width: "50%",
                                            fontSize: "13px",
                                          }}
                                          label="Exercise"
                                          placeholder="Exercise"
                                          onChange={handleSearch}
                                          InputLabelProps={{
                                            shrink: true,
                                            style: {
                                              fontSize: "14px",
                                            }, // You can adjust the font size as needed
                                          }}
                                        />
                                      )}
                                    /> */}

                                    <TextField
                                      id="outlined-basic"
                                      // label="Exercise"
                                      variant="outlined"
                                      placeholder="Set"
                                      type="number"
                                      value={data.set}
                                      name="set"
                                      onChange={(e) => handleChange(e, index)}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      label="Set"
                                      InputProps={{
                                        style: { fontSize: "13px" },
                                      }}
                                      sx={{
                                        borderRadius: "5px",
                                        // "& legend": { display: "none" },
                                        // "& fieldset": { top: 0 },
                                        fontSize: "13px",
                                        width: "10%",
                                      }}
                                    />

                                    <CloseIcon
                                      sx={{ opacity: 0.3, fontSize: "15px" }}
                                    />
                                    <TextField
                                      id="outlined-basic"
                                      // label="Exercise"
                                      variant="outlined"
                                      placeholder="0"
                                      type="number"
                                      value={data.time}
                                      name="time"
                                      onChange={(e) => handleChange(e, index)}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      label="Duration"
                                      InputProps={{
                                        style: { fontSize: "13px" },
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <div style={{ fontSize: "11px" }}>
                                              sec
                                            </div>
                                          </InputAdornment>
                                        ),
                                      }}
                                      sx={{
                                        borderRadius: "5px",

                                        fontSize: "13px",
                                        width: "15%",
                                      }}
                                    />
                                    {/* <FormControl sx={{ width: "15%" }}>
                                  <InputLabel
                                    id="demo-simple-select-label"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Duration
                                  </InputLabel>
                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="time"
                                    value={data.time}
                                    label="Duration"
                                    sx={{
                                      borderRadius: "5px",
                                      // "& legend": { display: "none" },
                                      // "& fieldset": { top: 0 },
                                      fontSize: "13px",
                                    }}
                                    onChange={(e) => handleChange(e, index)}
                                    //   input={<OutlinedInput label="Nav Items" />}
                                    MenuProps={MenuProps}
                                  >
                                    {menuLists.map((name) => (
                                      <MenuItem key={name} value={name}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl> */}
                                    <TextField
                                      id="outlined-basic"
                                      // label="Exercise"
                                      variant="outlined"
                                      placeholder="Reps"
                                      type="number"
                                      label="Reps"
                                      value={data.reps}
                                      name="reps"
                                      onChange={(e) => handleChange(e, index)}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        style: { fontSize: "13px" },
                                      }}
                                      sx={{
                                        borderRadius: "5px",
                                        // "& legend": { display: "none" },
                                        // "& fieldset": { top: 0 },
                                        fontSize: "13px",
                                        width: "11%",
                                      }}
                                    />

                                    <TextField
                                      sx={{
                                        borderRadius: "5px",
                                        // "& legend": { display: "none" },
                                        // "& fieldset": { top: 0 },

                                        width: "45%",
                                      }}
                                      // className={classes.customInput}
                                      id="custom-input"
                                      variant="outlined"
                                      placeholder="Weight"
                                      name="weight"
                                      label="Weight"
                                      value={data.weight}
                                      onChange={(e) => handleChange(e, index)}
                                      type="number"
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        style: { fontSize: "13px" },
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Select
                                              id="select-option"
                                              variant="outlined"
                                              size="small"
                                              name="weightType"
                                              value={data.weightType}
                                              label="Weight Type"
                                              onChange={(e) =>
                                                handleChange(e, index)
                                              }
                                              sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                                fontSize: "13px",
                                              }}
                                            >
                                              <MenuItem value="1rm">
                                                % 1RM
                                              </MenuItem>
                                              <MenuItem value="body_weight">
                                                % Bodyweight
                                              </MenuItem>
                                              <MenuItem value="kg">
                                                kilogram
                                              </MenuItem>
                                              <MenuItem value="lb">
                                                Pound
                                              </MenuItem>
                                              <MenuItem value="poods">
                                                Poods
                                              </MenuItem>
                                            </Select>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                    <TextField
                                      id="outlined-basic"
                                      // label="Exercise"
                                      variant="outlined"
                                      placeholder="0"
                                      type="number"
                                      value={data.rest}
                                      name="rest"
                                      onChange={(e) => handleChange(e, index)}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      label="Rest"
                                      InputProps={{
                                        style: { fontSize: "13px" },
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <div style={{ fontSize: "11px" }}>
                                              sec
                                            </div>
                                          </InputAdornment>
                                        ),
                                      }}
                                      sx={{
                                        borderRadius: "5px",

                                        fontSize: "13px",
                                        width: "15%",
                                      }}
                                    />
                                    {/* <FormControl sx={{ width: "15%" }}>
                                  <InputLabel id="demo-simple-select-label">
                                    Rest
                                  </InputLabel>{" "}
                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="rest"
                                    value={data.rest}
                                    placeholder="Rest"
                                    label="Rest"
                                    sx={{
                                      borderRadius: "5px",
                                      // "& legend": { display: "none" },
                                      // "& fieldset": { top: 0 },
                                      // width: "15%",
                                      fontSize: "13px",
                                    }}
                                    onChange={(e) => handleChange(e, index)}
                                    //   input={<OutlinedInput label="Nav Items" />}

                                    MenuProps={MenuProps}
                                  >
                                    <MenuItem value={0}>None</MenuItem>
                                    {menuLists.map((name) => (
                                      <MenuItem key={name} value={name}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl> */}
                                    <div className=" cross-icon ">
                                      {addArray.length > 1 ? (
                                        <>
                                          <Tooltip title="Remove">
                                            <RemoveCircleOutlineIcon
                                              onClick={() =>
                                                handleDelete(index)
                                              }
                                              className="diary-icon-remove"
                                            />
                                          </Tooltip>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <Tooltip title="Add">
                                        <AddCircleOutlineIcon
                                          onClick={() => handleAdd(index)}
                                          className="diary-icon-add"
                                        />
                                      </Tooltip>

                                      <CustomPopoverSection
                                        menu={memueOptions(index)}
                                        data={index}
                                      />
                                    </div>
                                  </Stack>

                                  {data?.open && (
                                    <TextField
                                      id="outlined-basic"
                                      // label="Exercise"
                                      size="small"
                                      variant="outlined"
                                      placeholder="Instructions"
                                      label="Instructions"
                                      type="number"
                                      value={data.description}
                                      name="description"
                                      multiline
                                      rows={2}
                                      onChange={(e) => handleChange(e, index)}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        style: { fontSize: "14px" },
                                      }}
                                      sx={{
                                        borderRadius: "5px",
                                        // "& legend": { display: "none" },
                                        // "& fieldset": { top: 0 },

                                        mt: 2,
                                        width: "100%",
                                      }}
                                    />
                                  )}
                                  <div className="text-center">
                                    {!data?.open ? (
                                      <KeyboardArrowDownIcon
                                        sx={{
                                          cursor: "pointer",
                                          opacity: 0.5,
                                          fontSize: "20px",
                                        }}
                                        onClick={() => {
                                          handleArrow(true, index);
                                        }}
                                      />
                                    ) : (
                                      <KeyboardArrowUpIcon
                                        sx={{
                                          cursor: "pointer",
                                          opacity: 0.5,
                                          fontSize: "20px",
                                        }}
                                        onClick={() => {
                                          handleArrow(false, index);
                                        }}
                                      />
                                    )}
                                  </div>
                                  <span
                                    className="cross-icon"
                                    style={{
                                      position: "absolute",
                                      bottom: "-37px",

                                      width: "100%",
                                      justifyContent: "center",
                                      zIndex: 1,
                                      // backgroundColor: "gray",
                                      // borderRadius: "50%",
                                      // padding: "4px 5px",
                                    }}
                                  >
                                    {addArray.length > 1 &&
                                    index < addArray.length - 1 ? (
                                      <div className="">
                                        <SyncIcon
                                          onClick={() => addSuperSet(index)}
                                          className="sync-icon-add"
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>

                                {/* <span className="cross-icon">
                  {addArray.length > 1 ? (
                    <div className="ps-3">
                      <CustomPopover menu={MENU_OPTIONS_MOVE} data={data} />
                    </div>
                  ) : (
                    ""
                  )}
                </span> */}
                              </div>
                            )}
                          </>
                        )}
                      </Draggable>
                    );
                    {
                      provided.placeholder;
                    }
                  })}
                  <div className="text-end mt-4">
                    <button className="small-contained-button">
                      {isLoading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};
export default EditWorkout;
