import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  InputAdornment,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { get_root_value } from "src/utils/domUtils";
import { useState } from "react";
import { s3baseUrl } from "src/config/config";

import AddWorkoutCalender from "../AddWorkoutCalender";
import AddWorkoutCalenderModel from "./AddWorkoutCalenderModel";
import { AllActiveWorkoutTypeListingApi } from "src/DAL/workoutType/workoutType";
import { useEffect } from "react";

import { WorkoutListingApi } from "src/DAL/workout/workout";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";

export default function AddWorkoutPopup({
  open,
  setOpen,
  componentToPassDown,
}) {
  const [selectedOption, setSelectedOption] = useState("existing");
  const [workouts, setWorkouts] = useState([]);
  const [dialogWidth, setDialogWidth] = useState("sm");
  const [workoutType, setWorkoutType] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [inputs, setInputs] = React.useState({
    title: "",
  });
  const [newWorkout, setNewWorkout] = useState(false);

  const [serchText, setSerchText] = useState("");
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleAgreeDelete = (value) => {
    // setDeleteDoc(value);
    setOpenCloseDialog(true);
  };
  const handleDelete = async () => {
    handleClose();
    setOpenCloseDialog(false);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const openSaveAsDialog = () => {
    setOpen(false);
    setModalOpen(true);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getWorkoutTypes = async () => {
    try {
      const result = await AllActiveWorkoutTypeListingApi();
      if (result.code === 200) {
        // console.log(result, "dfkjsdahaf");
        setWorkoutType(result?.workout_types);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}
  };
  const getExerciseList = async () => {
    let result = await WorkoutListingApi(0, 100, serchText);
    if (result.code == 200) {
      console.log(result, "kfaskjdfkla");
      // setIsLoading(false);

      setWorkouts(result?.workouts);
    }
  };
  useEffect(() => {
    getExerciseList();
  }, [serchText]);
  useEffect(() => {
    getWorkoutTypes();
  }, []);
  React.useEffect(() => {
    if (newWorkout) setDialogWidth("lg");
  }, [newWorkout]);
  React.useEffect(() => {
    if (open) {
      setDialogWidth("sm");
      setNewWorkout(false);
      setSelectedOption("existing");
      setInputs((values) => ({
        ...values,
        workout: "",
        title: "",
      }));
    }
  }, [open]);
  return (
    <div>
      <AddWorkoutCalenderModel
        onOpen={modalOpen}
        setOnOpen={setModalOpen}
        workoutTypes={workoutType}
      />
      <CustomConfirmation
        open={openCloseDialog}
        setOpen={setOpenCloseDialog}
        title={"Are you sure you want to Close ?"}
        handleAgree={handleDelete}
      />
      <Dialog
        open={open}
        // onClose={handleClose}
        // scroll="body"
        fullWidth
        maxWidth={dialogWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="show-dialog-on-full"
      >
        {!newWorkout ? (
          <div
            className={`text-end mt-1 d-flex justify-content-between align-items-center`}
          >
            <DialogTitle
              sx={{
                paddingY: 0,
                color: get_root_value("--portal-theme-primary"),
              }}
            >
              Choose an Option
            </DialogTitle>

            <IconButton
              className="back-screen-button me-1 "
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <div className={`text-end mt-1 me-1  `}>
            <IconButton
              className="back-screen-button"
              onClick={handleAgreeDelete}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}

        <>
          {!newWorkout ? (
            <DialogContent
              sx={{
                // paddingY: 0,
                maxHeight: "60vh", // Set a maximum height for the scrollable area
                overflowY: "auto", // Enable vertical scrolling when content overflows
              }}
            >
              <FormControl component="fieldset" fullWidth>
                <InputLabel id="select-label">Select Option</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  value={selectedOption}
                  onChange={handleOptionChange}
                  label="Select Option"
                >
                  <MenuItem value="existing">Use From Workout Library</MenuItem>
                  <MenuItem value="new">New Workout</MenuItem>
                </Select>
              </FormControl>
              {/* <RadioGroup
                sx={{
                  ml: 1,
                }}
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <FormControlLabel
                  value="new"
                  control={<Radio sx={{ padding: "0px" }} />}
                  label="New Workout"
                />
                <FormControlLabel
                  value="existing"
                  control={<Radio sx={{ padding: "0px" }} />}
                  label="Use Existing Workout"
                />
              </RadioGroup> */}

              {selectedOption == "existing" && (
                <div className="row mt-3">
                  <div className="col-lg-6 col-md-6 col-sm-12 ">
                    <Autocomplete
                      id="tags-outlined"
                      options={workouts}
                      getOptionLabel={(option) => option.title}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar
                              src={s3baseUrl + option?.image?.thumbnail_1}
                              alt={option.title}
                              sx={{
                                width: 30,
                                height: 30,
                                marginRight: 1,
                                borderRadius: "50%",
                              }}
                            />
                            {option.title}
                          </div>
                        </li>
                      )}
                      filterSelectedOptions
                      value={inputs.workout || null}
                      onChange={(event, newValue) => {
                        setInputs((values) => ({
                          ...values,
                          workout: newValue,
                          title: newValue?.title || "",
                        }));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Workouts"
                          placeholder="Workouts"
                          onChange={handleSearch}
                          InputProps={{
                            ...params.InputProps,
                            style: { paddingLeft: "6px" },
                            startAdornment: (
                              <InputAdornment position="start">
                                {inputs?.workout?.image?.thumbnail_1 && (
                                  <Avatar
                                    sx={{ borderRadius: "5px" }}
                                    alt="User Image"
                                    src={
                                      s3baseUrl +
                                      inputs?.workout?.image?.thumbnail_1
                                    }
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                    {/* <TextField
                  id="outlined-basic"
                  label="Workout Name"
                  variant="outlined"
                  fullWidth
                  required
                  name="title"
                  value={inputs.title}
                  onChange={handleChange}
                /> */}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 ">
                    <TextField
                      id="outlined-basic"
                      label="Workout Name"
                      variant="outlined"
                      fullWidth
                      required
                      name="title"
                      value={inputs.title}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}
            </DialogContent>
          ) : (
            <>
              <DialogContent
                sx={{
                  paddingY: 0,
                  height: "80vh", // Set a maximum height for the scrollable area
                  overflowY: "auto", // Enable vertical scrolling when content overflows
                }}
              >
                <AddWorkoutCalender />
              </DialogContent>
            </>
          )}
          {selectedOption == "existing" ? (
            <DialogActions>
              <Button
                sx={{ textTransform: "inherit" }}
                onClick={handleClose}
                color="primary"
              >
                Ok
              </Button>
            </DialogActions>
          ) : !newWorkout ? (
            <DialogActions>
              <Button
                onClick={() => {
                  setNewWorkout(true);
                }}
                sx={{ textTransform: "inherit" }}
                color="primary"
              >
                Continue
              </Button>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button
                onClick={handleClose}
                sx={{ textTransform: "inherit" }}
                color="primary"
              >
                Save only
              </Button>
              <Button
                onClick={() => {
                  openSaveAsDialog();
                }}
                color="primary"
                sx={{ textTransform: "inherit" }}
              >
                Save and save as template
              </Button>
            </DialogActions>
          )}
        </>
      </Dialog>
    </div>
  );
}
