import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { IconButton, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useState } from "react";

import AddWorkoutCalender from "../AddWorkoutCalender";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";

export default function EditWorkoutPopup({ open, setOpen }) {
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleAgreeDelete = (value) => {
    // setDeleteDoc(value);
    setOpenCloseDialog(true);
  };
  const handleDelete = async () => {
    handleClose();
    setOpenCloseDialog(false);
  };
  return (
    <div>
      <CustomConfirmation
        open={openCloseDialog}
        setOpen={setOpenCloseDialog}
        title={"Are you sure you want to Close ?"}
        handleAgree={handleDelete}
      />
      <Dialog
        open={open}
        // onClose={handleClose}
        // scroll="body"
        fullWidth
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="show-dialog-on-full"
      >
        <div className="text-end mt-2 me-2">
          <IconButton
            className="back-screen-button"
            onClick={handleAgreeDelete}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent
          sx={{
            paddingY: 0,
            height: "80vh", // Set a maximum height for the scrollable area
            overflowY: "auto", // Enable vertical scrolling when content overflows
          }}
        >
          <AddWorkoutCalender />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "inherit" }}
            onClick={handleClose}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
