import PropTypes from "prop-types";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import faker from "faker";
// material
import {
  Box,
  Card,
  Container,
  Link,
  Typography,
  Stack,
  Button,
  IconButton,
  TextField,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlayLessonIcon from "@mui/icons-material/PlayLesson";

import { useCallback, useEffect, useState } from "react";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/RecordNotFound";
import ActiveLastBreadcrumb from "src/components/ActivePaths";
import { AllExerciseListingApi } from "src/DAL/exercise/exercise";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import ExerciseFilter from "./ExerciseFilterNew";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import FilteredChip from "src/components/FilteredChip";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

const Lessons = [
  {
    id: faker.datatype.uuid(),
    lesson_slug: faker.datatype.uuid(),
    title: "Module 1: Secrets of Serenity",
    short_description:
      " Discover the 5 essential elements to quantum leap your results and create a repeatable winning formula. Freedom is a Mindset. Discover your Purpose",
  },
  {
    id: faker.datatype.uuid(),
    lesson_slug: faker.datatype.uuid(),
    title: "Module 2 - Creative Consciousness",
    short_description:
      " Discover the 5 essential elements to quantum leap your results and create a repeatable winning formula. Freedom is a Mindset 2.Discover your Purpose. Success is a Habit. Lead in the New Economy",
  },
  {
    id: faker.datatype.uuid(),
    lesson_slug: faker.datatype.uuid(),
    title: "Module 3: Abundance Accelerator",
    short_description:
      " Discover the 5 essential elements to quantum leap your results and create a repeatable winning formula.",
  },
];

// ----------------------------------------------------------------------

export default function ExercisListing() {
  const id = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [exercise, setExercise] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [filterCount, setFilterCount] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const EMPTY_FILTER = {
    exercises: [],
    mainMuscle: [],
    difficulty: [],
    search_text: "",
    status: "",
  };

  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  // const [filterStateUpdated, setFilterStateUpdated] = useState();
  const [updatedFilter, setUpdatedFilter] = useState(false);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const handleTagToggle2 = (tag) => {
    const newSelectedTags = filterState.exercises;
    console.log(newSelectedTags, "newSelectedTagsnewSelectedTags");
    const index = newSelectedTags.indexOf(tag);
    if (index === -1) {
      newSelectedTags.push(tag);
    } else {
      newSelectedTags.splice(index, 1);
    }
    setFilterState((values) => ({
      ...values,
      ["exercises"]: newSelectedTags,
    }));
  };
  const searchFunction = () => {
    setPage(0);
    // setPageCount(1);
    filterState.search_text = filterName;
    getExerciseList(filterState);
    localStorage.setItem("ExercisesListSearch", JSON.stringify(filterState));
    handleCloseFilterDrawer();
    setIsLoading(true);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    // setPageCount(1);

    getExerciseList(data);
    setFilterState(data);
    // localStorage.setItem("ExercisesListSearch", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    let clear_data = { ...EMPTY_FILTER };
    console.log(clear_data, "dslkjfjajsdklfka");
    setPage(0);
    // setPageCount(1);
    setFilterName("");
    setFilterState(clear_data);

    getExerciseList(clear_data);
    handleCloseFilterDrawer();
    setIsLoading(true);
    localStorage.removeItem("ExercisesListSearch");
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
    console.log(filterStateUpdated, "dkjhkfjhasdjhfasdkfaj", updatedFilter);
    setFilterState(filterStateUpdated);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  // let breadCrumbMenu = [
  //   {
  //     title: "Programmes",
  //     navigation: `/programmes`,
  //     active: false,
  //   },
  //   {
  //     title: `${id.slug}`,
  //     navigation: `/programmes/programmes-detail/${id.slug}`,
  //     active: false,
  //   },
  //   {
  //     title: "Programme Lessons",
  //     navigation: null,
  //     active: true,
  //   },
  // ];
  const fetchMoreData = () => {
    console.log("Fetching more data...");
    setRowsPerPage((prev) => prev + 50);
  };
  const getExerciseList = async (filterData) => {
    setUpdatedFilter(false);
    let postdata = {
      difficulty: filterData.difficulty,
      exercise: filterData.exercises,
      main_muscle: filterData.mainMuscle,
    };
    let result = await AllExerciseListingApi(
      page,
      rowsPerPage,
      filterName,
      postdata,
      filterData?.status
    );
    if (result.code == 200) {
      setUpdatedFilter(true);
      setFilterCount(
        filterData?.difficulty.length +
          filterData?.exercises.length +
          filterData?.mainMuscle.length
      );
      if (filterData?.status) {
        setFilterCount(
          filterData?.difficulty.length +
            filterData?.exercises.length +
            filterData?.mainMuscle.length +
            1
        );
      }

      let chipData = filterData;
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      console.log(chipData, "dsflkjasdlfa");

      setFilterStateUpdated(chipData);
      setIsLoading(false);
      console.log(result, "lessonListingApi");
      setExercise(result?.exercises);
      // setTotalPages(result.total_pages);
      setTotalCount(result.total_exercises);
    }
  };
  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("ExercisesListSearch");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setFilterName(filter_data.search_text);
      }
    }
    setFilterState(filter_data);
    setFilterStateUpdated(filter_data);
    //     getMemberListing(filter_data);
    // filterState.search_text = filterName;
    getExerciseList(filter_data);
  }, [page, rowsPerPage, filterName]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  //console.log(id.slug, "id");
  //console.log(lesson, "lessonlessonlesson");
  return (
    <>
      <div className="container">
        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filters"
          componentToPassDown={
            <ExerciseFilter
              filterData={filterState}
              setFilterData={setFilterState}
              searchSubmitFilter={searchFunction}
              handleClearFilter={handleClearFilter}
            />
          }
        />
        <div className="row mobile-margin display-flex mt-2">
          <div>
            {/* <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton> */}
            {/* <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} /> */}
          </div>
          <div className="col-lg-6 col-sm-12">
            <h2>Exercise </h2>
          </div>
          <div className="col-lg-6 col-sm-12 text-end ">
            <button
              className="small-contained-button me-1 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
              style={{ position: "relative" }}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              <span
                // className="small-contained-button"
                style={{
                  position: "absolute",
                  bottom: "23px",
                  right: "9px",
                }}
              >
                {filterCount ? (
                  <div className="dot-filter-count">{filterCount}</div>
                ) : (
                  <div></div>
                )}
              </span>
            </button>

            <button
              className="small-contained-button me-3"
              onClick={() =>
                navigate(`/exercise-add`, {
                  state: id?.slug,
                })
              }
            >
              Add Exercise
            </button>
          </div>
          <div className="col-lg-12 col-sm-12 d-flex justify-content-end">
            <TextField
              fullWidth
              label="Search"
              sx={{ marginRight: 2, width: 240 }}
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
            />
            {/* <button
              className="small-contained-button me-2 mt-1 mb-3"
              onClick={() =>
                navigate(`/add-exercise`, {
                  state: id?.slug,
                })
              }
            >
              Add Exercise
            </button>
            <button
              className="small-contained-button me-2 mt-1 mb-3"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button> */}
          </div>
        </div>{" "}
        {/* <div className=" text-end">
          <button
            className="small-contained-button"
            onClick={() =>
              navigate(`/add-exercise`, {
                state: id?.slug,
              })
            }
          >
            Add Exercise
          </button>
        </div> */}
        <div className="row section-space">
          {/* <FilteredChip
            data={filterStateUpdated}
            tempState={filterState}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          /> */}

          {/* <div className="col-12">
            <h1>
              {state?.name ? state?.name : lesson[0]?.program[0]?._id?.title}
            </h1>
            <h1>{state.slug}</h1>
          </div> */}
        </div>
        <div className="row mt-4">
          {exercise.length < 1 ? (
            <RecordNotFound title="Exercises" />
          ) : (
            <InfiniteScroll
              className="w-100 m-auto"
              dataLength={exercise.length}
              next={fetchMoreData}
              hasMore={exercise.length < totalCount ? true : false} // Set this to 'false' when you have loaded all available data.
              loader={<h4 className="text-center">Loading...</h4>}
            >
              <div className="row w-100 m-auto">
                {exercise.map((x, i) => {
                  return (
                    <>
                      <div className="col-lg-3 col-md-3 col-sm-12 mb-4" key={i}>
                        <div className="card mt-1 exercise-cards-shadow cursor h-100">
                          <img
                            src={s3baseUrl + x?.image?.thumbnail_1}
                            // src="https://images.pexels.com/photos/4473622/pexels-photo-4473622.jpeg?cs=srgb&dl=pexels-ketut-subiyanto-4473622.jpg&fm=jpg"
                            className="card-img-top pods-image"
                            alt="Lessons"
                            onClick={() =>
                              navigate(
                                `/exercise-details/${x?.exercise_slug}`,
                                {
                                  state: x,
                                }
                              )
                            }
                          />
                          <div className="card-body">
                            <div className="row d-flex align-items-end h-100">
                              <div className="col-12 ">
                                <div
                                  className="h2-heading fw-bold "
                                  // onClick={() => handleNavigateDetail(value)}
                                  onClick={() =>
                                    navigate(
                                      `/exercise-details/${x?.exercise_slug}`,
                                      {
                                        state: x,
                                      }
                                    )
                                  }
                                >
                                  {htmlDecode(x?.title)}
                                </div>

                                {!x?.status && (
                                  <Chip
                                    label={"Inactive"}
                                    variant="contained"
                                    color={"error"}
                                    sx={{
                                      fontSize: "10px",
                                      height: "auto",
                                      width: "auto",
                                    }}
                                  />
                                )}
                                {/* <span className="InActive px-1">Lessons</span> */}
                              </div>

                              {/* <div className="col-2 menu-option"> */}
                              {/* <CustomPopover menu={MENU_OPTIONS} data={value} /> */}
                              {/* </div> */}
                              {/* <CustomConfirmation
                        open={openDelete}
                        setOpen={setOpenDelete}
                        title={
                          "Are you sure you want to delete this recording?"
                        }
                        handleAgree={handleDelete}
                      /> */}
                            </div>
                            {/* <p
                          className="programme-card-desc mb-3"
                          // onClick={() => handleNavigateDetail(value)}
                        >
                          {htmlDecode(x?.short_description).substring(0, 250) +
                            "..."}
                        </p> */}
                            {/* <p className="programme-card-desc mb-3 programme-card-desc-muted">
                      <b className="text-muted">Programme:</b>{" "}
                      {value.program.title}
                    </p> */}
                            {/* <div className="row recording-card-date">
                        <div className="col-4 card-button recording-card-date-position">
                          <p className="pods-active-members">
                            <span>Lessons</span>
                          </p>
                        </div>
                        <div className="col-8 text-end ms-auto">
                          {value.total_lesson_duration}
                        </div>
                      </div> */}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </>
  );
}
