import { invokeApi } from "src/bl_libs/invokeApi";

export const getAllFormsList = async (page, rows, search) => {
  const requestObj = {
    path: `api/form/form_list?&page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: search,
  };
  return invokeApi(requestObj);
};
export const getAllTemplatesListByType = async (search) => {
  const requestObj = {
    path: `api/form_template/all_form_template?search=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getTemplateDetail = async (id) => {
  const requestObj = {
    path: `api/form_template/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addForm = async (data) => {
  const requestObj = {
    path: `api/form`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editForm = async (_id, data) => {
  const requestObj = {
    path: `api/form/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteForm = async (_id) => {
  const requestObj = {
    path: `api/form/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getTemplatesWithoutSearchAndInActive = async (data) => {
  const requestObj = {
    path: `api/feedback_template/get_feedback_template`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
