import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import goal24 from "@iconify/icons-octicon/goal-24";
import { project_name } from "src/config/config";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SettingsIcon from "@mui/icons-material/Settings";
import departmentLine from "@iconify/icons-mingcute/department-line";
import calendarIcon from "@iconify/icons-ep/calendar";
import { ListItemIcon } from "@mui/material";
import tv2 from "@iconify/icons-lucide/tv-2";
import personFill from "@iconify/icons-eva/person-fill";
import {
  DashboardIcons,
  Goal_Statement,
  PodsIcons,
  Profile,
  RecordingIcons,
} from "src/assets";
import { Icon } from "@iconify/react";
// ----------------------------------------------------------------------
const getMUIIcon = (name) => {
  return <ListItemIcon className="navbar-icon">{name}</ListItemIcon>;
};
const getImageIcon = (name) => (
  <img src={name} width={22} height={22} className="navbar-icon" />
);

const getEvaIcon = (name, hFlip) => (
  <Icon
    className="navbar-icon"
    icon={name}
    width={22}
    height={22}
    hFlip={hFlip}
  />
);

let sidebarConfig = ({ recording_name = "", type }) => {
  const sidebarConfig = [
    {
      title: "Programmes",
      path: "/programmes",
      icon: getEvaIcon(barchartfill),
    },
    {
      title: "Your Information",
      path: "/coach_forms",
      icon: getEvaIcon("heroicons-solid:information-circle"),
    },
  ];
  if (type !== "team") {
    sidebarConfig.push({
      title: "Members",
      path: "/members",
      icon: getEvaIcon("mdi:person", true),
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Exercises",

      icon: getEvaIcon("healthicons:exercise-walking"),
      child_options: [
        {
          title: "Exercise library",
          path: "/exercise",

          icon: getEvaIcon("healthicons:exercise-walking"),
        },
        {
          title: "Exercises Type",
          path: "/type-exercise",
          icon: getEvaIcon("healthicons:exercise-walking"),
        },
      ],
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Workout",

      icon: getEvaIcon("mdi:gym"),
      child_options: [
        {
          title: "workout Library",
          path: "/workout",

          icon: getEvaIcon("mdi:gym"),
        },
        {
          title: "workout Type",
          path: "/type-workout",
          icon: getEvaIcon("mdi:gym"),
        },
      ],
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Client Portal",

      icon: getEvaIcon("fluent:settings-20-filled"),
      child_options: [
        {
          title: "User Form",
          path: "/forms",

          icon: getEvaIcon("fluent:form-48-filled"),
        },

        {
          title: "User Form Templates",
          path: "/user_form-templates",

          icon: getEvaIcon("fluent:form-48-filled"),
        },
      ],
      /* "children" key can be added in these objects, example:children:[{...}] */
    });
  }

  // if (type !== "team") {
  //   sidebarConfig.push({
  //     title: "Goal Statement",
  //     path: "/goal-statement",
  //     icon: getEvaIcon(goal24, true),
  //   });
  // }
  // if (type !== "team") {
  //   sidebarConfig.push({
  //     title: "Member Goal Statement",
  //     icon: getEvaIcon(goal24, true),
  //     child_options: [
  //       {
  //         title: "Complete",
  //         path: "/member-completed-goalstatements",
  //         icon: getEvaIcon(goal24, true),
  //       },
  //       {
  //         title: "Incomplete",
  //         path: "/member-incomplete-goalstatements/",
  //         icon: getEvaIcon(goal24, true),
  //       },
  //       {
  //         title: "Responded",
  //         path: "/member-responded-goalstatements",
  //         icon: getEvaIcon(goal24, true),
  //       },
  //     ],
  //   });
  // }

  // if (type !== "team") {
  //   sidebarConfig.push({
  //     title: "Self Image",
  //     path: "/self-image",
  //     icon: getEvaIcon(goal24, true),
  //   });
  // }
  // if (type !== "team") {
  //   sidebarConfig.push({
  //     title: "Member Self Image",
  //     icon: getEvaIcon(goal24, true),
  //     child_options: [
  //       {
  //         title: "Complete",
  //         path: "/member-completed-selfImage",
  //         icon: getEvaIcon(goal24, true),
  //       },
  //       {
  //         title: "Incomplete",
  //         path: "/member-incomplete-selfImage/",
  //         icon: getEvaIcon(goal24, true),
  //       },
  //       {
  //         title: "Responded",
  //         path: "/member-responded-selfImage",
  //         icon: getEvaIcon(goal24, true),
  //       },
  //     ],
  //   });
  // }
  // if (type !== "team") {
  //   sidebarConfig.push({
  //     title: "Journal Prompt",
  //     path: "/journal-prompts",
  //     icon: getEvaIcon(goal24, true),
  //   });
  // }
  // if (type !== "team") {
  //   sidebarConfig.push({
  //     title: "Member Journal Prompt",
  //     icon: getEvaIcon(goal24, true),
  //     child_options: [
  //       {
  //         title: "Complete",
  //         path: "/member-completed-journalPrompt",
  //         icon: getEvaIcon(goal24, true),
  //       },
  //       {
  //         title: "Incomplete",
  //         path: "/member-incomplete-journalPrompt/",
  //         icon: getEvaIcon(goal24, true),
  //       },
  //       {
  //         title: "Responded",
  //         path: "/member-responded-journalPrompt",
  //         icon: getEvaIcon(goal24, true),
  //       },
  //     ],
  //   });
  // }
  // if (type !== "team") {
  //   sidebarConfig.push({
  //     title: `${recording_name} Recording`,
  //     path: "/recordings",
  //     icon: getEvaIcon(tv2),
  //   });
  // }
  // if (type !== "team") {
  //   sidebarConfig.push({
  //     title: "Community Rooms",
  //     path: "/links",
  //     icon: getEvaIcon(departmentLine),
  //   });
  // }
  // if (type !== "team") {
  //   sidebarConfig.push({
  //     title: "Calendar",
  //     icon: getEvaIcon(calendarIcon),
  //     child_options: [
  //       {
  //         title: "Groups",
  //         path: "/groups",
  //         icon: getEvaIcon(calendarIcon),
  //       },
  //       {
  //         title: "Calendar Events",
  //         path: "/calender",
  //         icon: getEvaIcon(calendarIcon),
  //       },
  //     ],
  //   });
  // }
  // if (type !== "consultant") {
  //   sidebarConfig.push({
  //     title: "Support Ticket",
  //     path: "/support-ticket",
  //     icon: getEvaIcon(calendarIcon),
  //   });
  // }
  return sidebarConfig;
};

export default sidebarConfig;
