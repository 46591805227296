import {
  Autocomplete,
  Avatar,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SyncIcon from "@mui/icons-material/Sync";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import { makeStyles } from "@mui/styles";
import faker from "faker";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import CloseIcon from "@mui/icons-material/Close";
import { ExerciseListingApi } from "src/DAL/exercise/exercise";

import { s3baseUrl } from "src/config/config";
import { AddWorkoutApi } from "src/DAL/workout/workout";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import ToshowExercise from "./modalToshowExercise";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 20,
    },
  },
};
const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const SuperSet = ({
  AddArray,
  Index,
  setAddArray,
  exercise,
  handleSearch,
  current,
}) => {
  const { state } = useLocation();
  //   const [serchText, setSerchText] = useState("");
  const parentDivRef = useRef(null);
  const [superSetFixHeight, setSuperSetFixHeight] = useState("");
  const [addArray, setaddArray] = useState(current.superSet);
  const [detailsData, setDetailsData] = useState("");
  const [openDetails, setOpenDetails] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [isReaload, setIsReaload] = useState(false);

  const classes = useStyles();
  const handleAdd = (i) => {
    let list = AddArray;
    list.splice(i + 1, 0, {
      exercise: "",
      set: "",
      time: "",
      weight: "",
      weightType: "kg",
      reps: "",
      rest: "",
      description: "",
      _id: faker.datatype.uuid(),
      superSet: [],
    });
    setAddArray([...list]);
    // const addedElement = [
    //   ...AddArray,
    //   {
    //     exercise: "",
    //     set: "",
    //     time: "",
    //     weight: "",
    //     weightType: "kg",
    //     reps: "",
    //     rest: "",
    //     description: "",
    //     _id: faker.datatype.uuid(),
    //     superSet: [],
    //   },
    // ];
    // setAddArray(addedElement);
  };
  const handleAddExercise = (i) => {
    setSuperSetFixHeight("");
    let list = AddArray;

    let secList = list[Index].superSet;
    secList.splice(i + 1, 0, {
      exercise: "",
      set: "",
      time: "",
      weight: "",
      weightType: "kg",
      reps: "",
      rest: "",
      description: "",
      _id: faker.datatype.uuid(),
      superSet: [],
    });
    setAddArray([...list]);
    // const addedElement = [
    //   ...AddArray,
    //   {
    //     exercise: "",
    //     set: "",
    //     time: "",
    //     weight: "",
    //     weightType: "kg",
    //     reps: "",
    //     rest: "",
    //     description: "",
    //     _id: faker.datatype.uuid(),
    //     superSet: [],
    //   },
    // ];
    // setAddArray(addedElement);
  };

  const handleDelete = (i) => {
    const delValue = [...AddArray];
    delValue.splice(i, 1);
    setAddArray(delValue);
  };
  const handleDeleteSet = (i) => {
    const delValue = [...AddArray];
    delValue.splice(i, 1);
    setAddArray(delValue);
  };
  const handleDeleteExercise = (i) => {
    setSuperSetFixHeight("");
    let delValue = AddArray;
    let secdelValue = delValue[Index].superSet;
    secdelValue.splice(i, 1);
    setAddArray([...delValue]);
  };
  const get_accessment_setting = async () => {
    setIsReaload(true);
    const result = await _get_attitude_accessment_setting();
    if (result.code == 200) {
      if (result.assessment_setting[level]?.length > 0) {
        const config_array = result.assessment_setting[level]?.map(
          (config, i) => {
            return {
              ...config,
              id: "coin" + i,
            };
          }
        );
        setaddArray(config_array);
      }
      setIsReaload(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsReaload(false);
    }
  };

  const handleArrow = (value, index) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[index]["open"] = value;
    setaddArray(list);
  };
  const addSuperSet = (index) => {
    //  const { name, value } = e.target;
    let array = addArray[index].superSet;
    array.push(addArray[index]._id);
    array.push(addArray[index + 1]._id);
    const list = [...addArray];
    list[index]["superSet"] = array;
    setaddArray(list);
  };
  const removeSuperSet = (index) => {
    //  const { name, value } = e.target;
    const list = [...AddArray];
    let firstPart = list[Index]["superSet"].slice(0, index + 1);
    let secondPart = list[Index]["superSet"].slice(index + 1);

    let firstObject = { ...firstPart[0] };
    if (index > 0) firstObject.superSet = firstPart;

    let secondObject = { ...secondPart[0] };
    if (secondPart.length > 1) secondObject.superSet = secondPart;

    let list1 = AddArray;
    list1.splice(Index + 1, 0, secondObject);
    list1.splice(Index + 1, 0, firstObject);
    setAddArray([...list1]);
    handleDeleteSet(Index);
    // list[index]["superSet"] = [];
    // setaddArray(list);
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...AddArray];
    list[Index]["superSet"][index][name] = value;
    // list[index][name] = value;

    setAddArray(list);
  };
  const handleChangeSuperSet = (e, index) => {
    const { name, value } = e.target;
    console.log(name, value, "sdfjkhdskjha");
    const list = [...AddArray];
    // list[Index]["superSet"][index][name] = value;
    list[Index][name] = value;

    setAddArray(list);
  };
  const handleChangeOther = (e, index) => {
    const list = [...AddArray];
    list[Index]["superSet"][index]["exercise"] = e;
    list[Index]["superSet"][index]["description"] = e?.instructions;
    setAddArray(list);
  };
  function handleOnDragEnd(result) {
    // const items = Array.from(AddArray[Index].superSet);
    // const [reorderedItem] = items.splice(result.source.index, 1);
    // items.splice(result.destination.index, 0, reorderedItem);
    // const list = [...AddArray];
    // list[Index]["superSet"] = items;

    // setaddArray(list);
    let list1 = AddArray;
    let secList = list1[Index].superSet;
    const [reorderedItem2] = secList.splice(result.source.index, 1);
    console.log("dskjfhkjdsahfjahk", reorderedItem2);
    secList.splice(result.destination.index, 0, reorderedItem2);
    setAddArray([...list1]);
  }
  const handleDragStart = (start) => {
    if (parentDivRef.current) {
      const parentHeight = parentDivRef.current.clientHeight;

      setSuperSetFixHeight(parentHeight);
    }

    // You can access other properties from the `start` object as needed
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    console.log(value, "dsfjksjdlkfjalkdja");
    const items = Array.from(AddArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);
    setAddArray(items);
  };
  const handleExerciseList = (value) => {
    console.log("sdkjfhkjadshaf", value);
    setDetailsData(value);
    setOpenDetails(true);
  };
  const handleSelectExercise = (value) => {
    console.log(value, "dsfhkjahsjkdhfkjah", Index, detailsData);
    const list = [...AddArray];

    list[Index]["superSet"][detailsData]["exercise"] = value;
    list[Index]["superSet"][detailsData]["description"] = value?.instructions;

    setAddArray(list);
  };
  const handleChangeDown = (value) => {
    console.log(value, "dsfjksjdlkfjalkdja", AddArray.length);
    if (value >= AddArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(AddArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);
    setAddArray(items);
  };
  const memueOptions = (value) => {
    console.log(value, "thethe");
    const MENU_OPTIONS = [];

    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= AddArray.length - 1 ? true : false,
      }
    );

    return MENU_OPTIONS;
  };
  useEffect(() => {
    console.log(current, "dkfjahdsjkhfkjashdkjfahsdkj");
    setSuperSetFixHeight("");
  }, [current?.superSet]);
  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div
      className="container subset-card mb-2 py-3 "
      style={{
        height: superSetFixHeight ? superSetFixHeight : "100%",
        minWidth: "936px",
      }}
      ref={parentDivRef}
    >
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <div className="">
        <div className="col-12">
          <div className="col-12 ">
            <p className="d-flex justify-content-between">
              <div className="d-flex align-items-center w-100 ">
                <div
                //   dangerouslySetInnerHTML={{
                //     // __html: lessonDetailInfo?.detailed_description,
                //     __html: "Superset of",
                //   }}
                >
                  Superset of
                </div>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  placeholder="Sets"
                  type="number"
                  value={current.superset_sets}
                  name="superset_sets"
                  onChange={(e) => handleChangeSuperSet(e)}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: "14px" },
                  }}
                  //   label="Set"
                  InputProps={{ style: { fontSize: "13px" } }}
                  sx={{
                    mx: 1,
                    borderRadius: "5px",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    fontSize: "13px",
                    width: "6%",
                  }}
                />
                <div>sets</div>
              </div>

              <div className="cross-icon">
                {AddArray.length > 1 ? (
                  <>
                    <Tooltip title="Remove">
                      <RemoveCircleOutlineIcon
                        onClick={() => handleDelete(AddArray.indexOf(current))}
                        className="diary-icon-remove"
                      />
                    </Tooltip>
                  </>
                ) : (
                  ""
                )}
                <Tooltip title="Add">
                  <AddCircleOutlineIcon
                    onClick={() => handleAdd(AddArray.indexOf(current))}
                    className="diary-icon-add"
                  />
                </Tooltip>
                <div>
                  <CustomPopoverSection
                    menu={memueOptions(Index)}
                    data={Index}
                  />
                </div>
              </div>
            </p>
          </div>
        </div>
        <DragDropContext
          //   onDragStart={handleDragStart}
          onDragEnd={handleOnDragEnd}
        >
          <Droppable droppableId="characters">
            {(provided) => (
              <div
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <form>
                  {current?.superSet?.map((data, index) => {
                    console.log(data, "dfkjdksjahfjsd");
                    return (
                      <Draggable
                        key={data._id}
                        draggableId={data._id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            // direction="row"

                            className={`d-flex  subset-workout-card `}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            onMouseDown={handleDragStart}
                          >
                            <div
                              style={{ width: "100%", position: "relative" }}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                justifyContent="center"
                              >
                                {/* <Autocomplete
                                  id="tags-outlined"
                                  options={exercise}
                                  getOptionLabel={(option) => option.title}
                                  renderOption={(props, option) => (
                                    <li {...props}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={
                                            s3baseUrl +
                                            option?.image?.thumbnail_1
                                          }
                                          alt={option.title}
                                          style={{
                                            width: 30,
                                            height: 30,
                                            marginRight: 10,
                                            borderRadius: "50%",
                                          }}
                                        />
                                        {option.title}
                                      </div>
                                    </li>
                                  )}
                                  filterSelectedOptions
                                  value={data.exercise ? data.exercise : null}
                                  onChange={(event, newValue) => {
                                    handleChangeOther(newValue, index);
                                  }}
                                  sx={{ width: "50%", fontSize: "13px" }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{
                                        //   borderRadius: "5px",
                                        //   "& legend": { display: "none" },
                                        //   "& fieldset": { top: 0 },

                                        //   // width: "50%",
                                        fontSize: "13px",
                                      }}
                                      label="Exercise"
                                      placeholder="Exercise"
                                      onChange={(e) => handleSearch(e)}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: {
                                          fontSize: "14px",
                                        }, // You can adjust the font size as needed
                                      }}
                                    />
                                  )}
                                /> */}
                                <TextField
                                  id="outlined-basic"
                                  // label="Exercise"
                                  variant="outlined"
                                  placeholder="Add Exercise"
                                  value={data?.exercise?.title}
                                  name="Exercise"
                                  onClick={() => handleExerciseList(index)}
                                  // onChange={(e) => handleChange(e, index)}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: "14px" },
                                  }}
                                  label="Exercise"
                                  InputProps={{
                                    style: {
                                      fontSize: "13px",
                                      paddingLeft: "6px",
                                    },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {data?.exercise?.image?.thumbnail_1 && (
                                          <Avatar
                                            sx={{ borderRadius: "5px" }}
                                            alt="User Image"
                                            src={
                                              s3baseUrl +
                                              data?.exercise?.image?.thumbnail_1
                                            }
                                          />
                                        )}
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <div style={{ fontSize: "16px" }}>
                                          {data?.exercise ? (
                                            <EditIcon
                                              fontSize="12px"
                                              sx={{ opacity: 0.5 }}
                                            />
                                          ) : (
                                            <AddIcon sx={{ opacity: 0.5 }} />
                                          )}
                                        </div>
                                      </InputAdornment>
                                    ),
                                    inputProps: {
                                      readOnly: true, // Disable editing
                                    },
                                  }}
                                  sx={{
                                    borderRadius: "5px",

                                    fontSize: "13px",
                                    width: "50%",
                                  }}
                                />
                                <TextField
                                  id="outlined-basic"
                                  // label="Exercise"
                                  variant="outlined"
                                  placeholder="Set"
                                  type="number"
                                  value={data.set}
                                  name="set"
                                  onChange={(e) => handleChange(e, index)}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: "14px" },
                                  }}
                                  label="Set"
                                  InputProps={{ style: { fontSize: "13px" } }}
                                  sx={{
                                    borderRadius: "5px",
                                    // "& legend": { display: "none" },
                                    // "& fieldset": { top: 0 },
                                    fontSize: "13px",
                                    width: "10%",
                                  }}
                                />

                                <CloseIcon
                                  sx={{ opacity: 0.3, fontSize: "15px" }}
                                />
                                <TextField
                                  id="outlined-basic"
                                  // label="Exercise"
                                  variant="outlined"
                                  placeholder="0"
                                  type="number"
                                  value={data.time}
                                  name="time"
                                  onChange={(e) => handleChange(e, index)}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: "14px" },
                                  }}
                                  label="Duration"
                                  InputProps={{
                                    style: { fontSize: "13px" },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <div style={{ fontSize: "11px" }}>
                                          sec
                                        </div>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    borderRadius: "5px",

                                    fontSize: "13px",
                                    width: "15%",
                                  }}
                                />
                                {/* <FormControl sx={{ width: "15%" }}>
                                  <InputLabel
                                    id="demo-simple-select-label"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Duration
                                  </InputLabel>
                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="time"
                                    value={data.time}
                                    label="Duration"
                                    sx={{
                                      borderRadius: "5px",
                                      // "& legend": { display: "none" },
                                      // "& fieldset": { top: 0 },
                                      fontSize: "13px",
                                    }}
                                    onChange={(e) => handleChange(e, index)}
                                    //   input={<OutlinedInput label="Nav Items" />}
                                    MenuProps={MenuProps}
                                  >
                                    {menuLists.map((name) => (
                                      <MenuItem key={name} value={name}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl> */}
                                <TextField
                                  id="outlined-basic"
                                  // label="Exercise"
                                  variant="outlined"
                                  placeholder="Reps"
                                  type="number"
                                  label="Reps"
                                  value={data.reps}
                                  name="reps"
                                  onChange={(e) => handleChange(e, index)}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: "14px" },
                                  }}
                                  InputProps={{ style: { fontSize: "13px" } }}
                                  sx={{
                                    borderRadius: "5px",
                                    // "& legend": { display: "none" },
                                    // "& fieldset": { top: 0 },
                                    fontSize: "13px",
                                    width: "11%",
                                  }}
                                />

                                <TextField
                                  sx={{
                                    borderRadius: "5px",
                                    // "& legend": { display: "none" },
                                    // "& fieldset": { top: 0 },

                                    width: "45%",
                                  }}
                                  // className={classes.customInput}
                                  id="custom-input"
                                  variant="outlined"
                                  placeholder="Weight"
                                  name="weight"
                                  label="Weight"
                                  value={data.weight}
                                  onChange={(e) => handleChange(e, index)}
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: "14px" },
                                  }}
                                  InputProps={{
                                    style: { fontSize: "13px" },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Select
                                          id="select-option"
                                          variant="outlined"
                                          size="small"
                                          name="weightType"
                                          value={data.weightType}
                                          label="Weight Type"
                                          onChange={(e) =>
                                            handleChange(e, index)
                                          }
                                          sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                            fontSize: "13px",
                                          }}
                                        >
                                          <MenuItem value="1rm">% 1RM</MenuItem>
                                          <MenuItem value="bodyweight">
                                            % Bodyweight
                                          </MenuItem>
                                          <MenuItem value="kg">
                                            kilogram
                                          </MenuItem>
                                          <MenuItem value="lb">Pound</MenuItem>
                                          <MenuItem value="poods">
                                            Poods
                                          </MenuItem>
                                        </Select>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <TextField
                                  id="outlined-basic"
                                  // label="Exercise"
                                  variant="outlined"
                                  placeholder="0"
                                  type="number"
                                  value={data.rest}
                                  name="rest"
                                  onChange={(e) => handleChange(e, index)}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: "14px" },
                                  }}
                                  label="Rest"
                                  InputProps={{
                                    style: { fontSize: "13px" },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <div style={{ fontSize: "11px" }}>
                                          sec
                                        </div>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    borderRadius: "5px",

                                    fontSize: "13px",
                                    width: "15%",
                                  }}
                                />
                                {/* <FormControl sx={{ width: "15%" }}>
                                  <InputLabel id="demo-simple-select-label">
                                    Rest
                                  </InputLabel>{" "}
                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="rest"
                                    value={data.rest}
                                    placeholder="Rest"
                                    label="Rest"
                                    sx={{
                                      borderRadius: "5px",
                                      // "& legend": { display: "none" },
                                      // "& fieldset": { top: 0 },
                                      // width: "15%",
                                      fontSize: "13px",
                                    }}
                                    onChange={(e) => handleChange(e, index)}
                                    //   input={<OutlinedInput label="Nav Items" />}

                                    MenuProps={MenuProps}
                                  >
                                    <MenuItem value={0}>None</MenuItem>
                                    {menuLists.map((name) => (
                                      <MenuItem key={name} value={name}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl> */}
                                <div className=" cross-icon ">
                                  {addArray.length > 2 ? (
                                    <>
                                      <Tooltip title="Remove">
                                        <RemoveCircleOutlineIcon
                                          onClick={() =>
                                            handleDeleteExercise(index)
                                          }
                                          className="diary-icon-remove"
                                        />
                                      </Tooltip>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <Tooltip title="Add">
                                    <AddCircleOutlineIcon
                                      onClick={() => handleAddExercise(index)}
                                      className="diary-icon-add"
                                    />
                                  </Tooltip>
                                </div>
                              </Stack>

                              {data?.open && (
                                <TextField
                                  id="outlined-basic"
                                  // label="Exercise"
                                  size="small"
                                  variant="outlined"
                                  placeholder="Instructions"
                                  label="Instructions"
                                  type="number"
                                  value={data.description}
                                  name="description"
                                  multiline
                                  rows={2}
                                  onChange={(e) => handleChange(e, index)}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: "14px" },
                                  }}
                                  InputProps={{ style: { fontSize: "14px" } }}
                                  sx={{
                                    borderRadius: "5px",
                                    // "& legend": { display: "none" },
                                    // "& fieldset": { top: 0 },

                                    mt: 2,
                                    width: "100%",
                                  }}
                                />
                              )}
                              <div className="text-center">
                                {!data?.open ? (
                                  <KeyboardArrowDownIcon
                                    sx={{
                                      cursor: "pointer",
                                      opacity: 0.5,
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      handleArrow(true, index);
                                    }}
                                  />
                                ) : (
                                  <KeyboardArrowUpIcon
                                    sx={{
                                      cursor: "pointer",
                                      opacity: 0.5,
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      handleArrow(false, index);
                                    }}
                                  />
                                )}
                              </div>
                              <span
                                className="cross-icon"
                                style={{
                                  position: "absolute",
                                  bottom: "-32px",
                                  width: "100%",
                                  justifyContent: "center",
                                  zIndex: 1,
                                  // backgroundColor: "gray",
                                  // borderRadius: "50%",
                                  // padding: "4px 5px",
                                }}
                              >
                                {current?.superSet.length > 1 &&
                                index < current?.superSet.length - 1 ? (
                                  <div className="">
                                    {/* {data.superSet.length <= 0 ? (
                                      <SyncIcon
                                        onClick={() => addSuperSet(index)}
                                        className="sync-icon-add"
                                      />
                                    ) : ( */}
                                    <SyncDisabledIcon
                                      onClick={() => removeSuperSet(index)}
                                      className="sync-icon-remove"
                                    />
                                    {/* )} */}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                    {
                      provided.placeholder;
                    }
                  })}
                </form>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};
export default SuperSet;
