import { useFormik } from "formik";
import { useEffect, useState } from "react";
// material
import {
  CircularProgress,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// components

//
import PRODUCTS from "../../_mocks_/products";
import { ProgrammesList } from "../../components/_dashboard/programmes";
//
import { programmesListing } from "../../DAL/Programmes/Programmes";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { filter } from "lodash";
import { useNavigate } from "react-router-dom";
import { get_root_value } from "src/utils/domUtils";
import { UserListToolbar } from "src/components/_dashboard/user";
import SearchNotFound from "src/components/SearchNotFound";
import { bgConsImage } from "src/assets";
import CustomPopover from "src/components/MenuOption/CustomPopover";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
export default function Programmes() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [orderBy, setOrderBy] = useState("number");
  const [order, setOrder] = useState("asc");
  const [program, setProgram] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterName, setFilterName] = useState("");
  const formik = useFormik({
    initialValues: {
      gender: "",
      category: "",
      colors: "",
      priceRange: "",
      rating: "",
    },
    onSubmit: () => {
      setOpenFilter(false);
    },
  });

  const { resetForm, handleSubmit } = formik;
  const programsList = async () => {
    const result = await programmesListing();
    ////console.log(result, "kkkkkkkkkkk");
    if (result.code == 200) {
      let res = [
        {
          action_by: "admin_user",
          action_id: "6465b185fb021a104be41c7f",
          audio_file: "",
          createdAt: "2023-07-21T04:44:52.533Z",

          is_default: true,
          is_program_show_on_list: true,
          locked_program_info: {
            video_url: "",
            short_description: "",
            detailed_description: "",
            image: "",
          },
          nav_items: [],
          no_of_lesson: 17,
          no_of_limited_days: 90,
          no_of_start_days: 0,
          order: 1,
          program_access_type: "limited",
          program_images: {
            thumbnail_1:
              "https://media.istockphoto.com/id/922112426/photo/person-writing-workout-plan-in-notebook.jpg?s=612x612&w=0&k=20&c=qBQrdMpBrjEZrpAAbVksFyZPPFmzBDNdDdAH69qbArU=",
            thumbnail_2: "program/b6a3c3f8-91e5-4be2-a46a-e672a46077ad.png",
            thumbnail_3: "program/3408d4cd-7ff6-412a-9bde-4b96313aa338.png",
          },
          program_slug: "default-program-1",
          short_description:
            "Lorem ipsum dolor sit amet consectetur adipiscing, elit penatibus leo aptent eros rhoncus magnis.",
          status: true,
          title: "90 Days Transformation",
          total_lesson_duration: "39 hr 15 min 17 sec",
          updatedAt: "2023-12-01T05:12:22.428Z",
          video_url: "https://vimeo.com/46926279",
        },
      ];
      setIsLoading(false);
      // setProgram(result.program);
      setProgram(res);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateDetail = (value) => {
    ////console.log(value, "valueeeee");
    navigate(`/programmes/programmes-detail/${value.program_slug}`, {
      state: { detailValues: value },
    });
  };
  const filteredPrograme = applySortFilter(
    program,
    getComparator(order, orderBy),
    filterName
  );
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };
  useEffect(() => {
    programsList();
  }, []);
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      // handleClick: handleEdit,
    },

    {
      label: "Programming",
      icon: "fluent:calendar-48-regular",
      handleClick: () => navigate("/programmes-calendar"),
    },
  ];
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  const isRecordNotFound = filteredPrograme.length === 0;
  console.log(isRecordNotFound, "dsflksd");
  return (
    <div className="container mt-2">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <h2>Programmes </h2>
        </div>
        <div className="col-lg-6 col-sm-12 d-flex justify-content-end">
          <TextField
            fullWidth
            label="Search"
            sx={{ marginRight: 2, width: 240 }}
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
          <button
            onClick={() => navigate(`/programmes/addProgram`)}
            className="small-contained-button"
          >
            Add Programme
          </button>
        </div>
        {filteredPrograme.map((value, index) => {
          return (
            <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
              <div className="card mt-4 pods-cards-shadow cursor h-100">
                <img
                  src={value.program_images.thumbnail_1}
                  className="card-img-top pods-image"
                  alt="Programme"
                />
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <h3 className="h2-heading">{htmlDecode(value.title)}</h3>
                    </div>

                    <div className="col-2 programmes-menu-option">
                      <CustomPopoverSection menu={MENU_OPTIONS} data={value} />
                    </div>
                  </div>
                  <p className="programme-card-desc mb-3">
                    {htmlDecode(value.short_description)}
                  </p>

                  <div className="row recording-card-date">
                    <div className="col-5 card-button recording-card-date-position">
                      <p className="pods-active-members">
                        <span> {value.no_of_lesson} Workouts</span>
                      </p>
                    </div>
                    <div className="col-7 text-end ms-auto">
                      {value.total_lesson_duration}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {isRecordNotFound && (
          <Typography
            variant="h5"
            align="center"
            style={{ marginTop: 90 }}
            justifyContent="center"
          >
            No results found
          </Typography>
        )}
      </div>
    </div>
  );
}
